/* eslint-disable react/no-array-index-key */
import React from "react"
import BackgroundImage from "../components/background-image"
import { SectionType } from "../../../types"
import { createMarkup } from "../../../../utils/markup-creator"

type SectionProps = {
  section: SectionType
  index: any
  [key: string]: any
}

const SimpleLogoCloud = ({ section, index, ...props }: SectionProps) => {
  return (
    <div className={`relative ${section.advanced?.class || ``}`}>
      <BackgroundImage
        url={section.advanced?.backgroundImageLg}
        mobileUrl={section.advanced?.backgroundImageSm}
        height={section.advanced?.backgroundHeight}
        backgroundPosition={section.advanced?.backgroundPosition}
        backgroundColor={section.advanced?.backgroundColor}
        backgroundFit={section.advanced?.backgroundFit}
      />
      <div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-0 py-12 lg:py-20">
        <div className="flow-root self-center">
          <div className="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
            {section.blurb && (
              <div className="m-4 ml-8 w-full md:w-auto md:flex md:flex-grow md:flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4">
                <div dangerouslySetInnerHTML={createMarkup(section.blurb)} />
              </div>
            )}

            {section.links &&
              section.links.map((link, linkIndex) => (
                <div key={`link-${linkIndex}`} className="mt-4 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4">
                  {link.url && (
                    <a href={link.url || `#`}>
                      <img className={link.class || "h-12 lg:h-12"} src={link.icon} alt={link.name} />
                    </a>
                  )}
                  {!link.url && <img className={link.class || "h-12 lg:h-12"} src={link.icon} alt={link.name} />}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SimpleLogoCloud
